$color: (
	"success": #4caf50,
	"error": #f44336,
	"alert":#ffc107,
	"thead-link": rgba(0, 0, 0, 0.87)
);
$font-ja: Arial, Roboto,"ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;

@for $i from 1 through 10 {
	.m#{$i * 10} {
		margin: #{$i * 10}px;
	}
	.mb#{$i * 10} {
		margin-bottom: #{$i * 10}px;
	}
	.mt#{$i * 10} {
		margin-top: #{$i * 10}px;
	}
	.ml#{$i * 10} {
		margin-left: #{$i * 10}px;
	}
	.mr#{$i * 10} {
		margin-right: #{$i * 10}px;
	}
	.p#{$i * 10} {
		padding: #{$i * 10}px;
	}
	.pb#{$i * 10} {
		padding-bottom: #{$i * 10}px;
	}
	.pt#{$i * 10} {
		padding-top: #{$i * 10}px;
	}
	.pl#{$i * 10} {
		padding-left: #{$i * 10}px;
	}
	.pr#{$i * 10} {
		padding-right: #{$i * 10}px;
	}
	.top#{$i * 10} {
		top: #{$i * 10}px;
	}
	.bottom#{$i * 10} {
		bottom: #{$i * 10}px;
	}
	.right#{$i * 10} {
		right: #{$i * 10}px;
	}
	.left#{$i * 10} {
		left: #{$i * 10}px;
	}

}

.posr{
	position: relative;
}
.posa{
	position: absolute;
}
.posf{
	position: fixed;
}
